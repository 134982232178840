<template>
  <div class="church-list template-1">
    <header class="page-header container">
      <h1 class="text-uppercase">{{ translations.tcWidowsList }}</h1>
    </header>
    <camp-selector @camp_changed="page_load()" :showCamp="false" :tooltip="translations.tcCurrentHome"
      :i18n="translations.components"></camp-selector>
    <data-table :i18n="translations.components" :fields="fields" :listType="list_type" :items="widowListAlter"
      :addItems="addItems" :toggleMe="true" :handleDeleteAction="false" :handleEditAction="true"
      :handleViewAction="false" :csvUrl="csvUrl" :reportName="reportName"></data-table>
    <footer class="page-footer"></footer>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import CampSelect from '@/components/CampSelect.vue'
import DataTable from '@/components/DataTableMemberChurches.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'widows-list',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      list_type: 'camp_member_churches',
      fields: [
        { key: 'sort_name', label: 'Name', sortable: true },
        { key: 'camp_name', label: 'Camp Name', sortable: true },
      ],
      hiddenItems: {
        display: false,
      },
      addItems: {
        display: false,
        text: 'Add Speaker', // Add Visit
        action: 'addPresenter',
      },
      reportName: 'WidowsListReport',
    }
  },
  methods: {
    ...mapActions({
      getMemberChurches: 'campModule/getMemberChurches',
      getWidowsList: 'directory/getWidowsList',
      loadChurches: 'churchMinistry/loadChurches',
      setLoadingStatus: 'menu/setLoadingStatus',
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
        await this.getWidowsList(this.userStateKey)
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    loadTranslations() {
      this.addItems.text = this.translations.tcAddMember
      this.fields[0].label = this.translations.tcName
      this.fields[1].label = this.translations.tcCampName
    },
  },
  async created() {
    await Promise.all([
      await this.getViewTranslations(),
      await this.getComponentTranslations('camp-select', 'data-table-member-churches'),
    ]).then((results) => {
      this.$set(this.translations, 'components', results[1])
      this.loadTranslations()
    })
  },
  computed: {
    ...mapGetters({
      csvUrl: 'directory/widowsReportCsv',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      prefCulture: 'user/userPreferredCulture',
      userCampKey: 'user/userCampKey',
      userId: 'user/userId',
      userOrgKey: 'user/userOrgKey',
      userStateKey: 'user/userStateKey',
      widowsList: 'directory/widowsList',
    }),
    widowListAlter() {
      let widowLst = this.widowsList.map((x) => {
        return {
          camp_name: x.camp_name,
          vin_ind_key: x.ind_key,
          name: x.name,
          sort_name: x.sort_name,
        }
      })
      return widowLst
    },
  },
  components: {
    campSelector: CampSelect,
    dataTable: DataTable,
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/views/ChurchList.scss';

.cwidth {
  min-width: 252px !important;
}
</style>
